import React, { useState } from "react";
import marine from "../../Assets/images/Banner/marine.jpg";
import marineScrap from "../../Assets/images/Banner/marine-scrap.jpg";
import leftoverMarine from "../../Assets/images/Banner/leftover-marine.jpg";
import leftoverShip from "../../Assets/images/Banner/leftovership.jpg";
import { Container, Row, Col, Pagination, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import BlogBanner from "../../Assets/images/Banner/blog.jpg";
import "./Blogs.scss";

const recentBlogs = [
  {
    id: 1,
    image: marine,
    title: "Marine Automation in 2024: Trends, Challenges, and Opportunities",
    excerpt:
      "Marine automation has been steadily advancing over the past few years, and 2024",
  },
  {
    id: 2,
    image: marineScrap,
    title: "Effect of Marine Scrap on the Modern World",
    excerpt:
      "Marine scrap, the discarded materials from ships and marine equipment, has a significant impact on the modern world. As the maritime industry continues to grow",
  },
  {
    id: 3,
    image: leftoverShip,
    title: "What We Can Do to Avoid Leftover Marine Products",
    excerpt:
      "The maritime industry generates a significant amount of leftover products, such as panels and automation equipment, at the end of their lifecycle",
  },
  //   {
  //     id: 4,
  //     image: ship,
  //     title: "Automation at its best",
  //     excerpt:
  //       "Automation is automation which comes from automation and only the automation, and if automation is from automation then its the real automation",
  //   },
  //   {
  //     id: 5,
  //     image: motor,
  //     title: "Automation at its best",
  //     excerpt:
  //       "Automation is automation which comes from automation and only the automation, and if automation is from automation then its the real automation",
  //   },
  //   {
  //     id: 6,
  //     image: generator,
  //     title: "Automation at its best",
  //     excerpt:
  //       "Automation is automation which comes from automation and only the automation, and if automation is from automation then its the real automation",
  //   },
];

const Blog = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const blogsPerPage = 6;

  const indexOfLastBlog = currentPage * blogsPerPage;
  const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
  const currentBlogs = recentBlogs.slice(indexOfFirstBlog, indexOfLastBlog);

  const totalPages = Math.ceil(recentBlogs.length / blogsPerPage);
  return (
    <div className="blog-container">
      <div className="banner">
        <img src={BlogBanner} alt="Banner" className="banner-image" />
        <h1 className="banner-title">Blogs</h1>
      </div>
      <Container className="blog-page">
        <Row>
          {currentBlogs.map((blog) => (
            <Col md={4} key={blog.id}>
              <Card className="blog-card">
                <Card.Img variant="top" src={blog.image} />
                <Card.Body>
                  <Card.Title>{blog.title}</Card.Title>
                  <Card.Text>{blog.excerpt}</Card.Text>
                  <Link to={`/blog/${blog.id}`} className="btn btn-primary">
                    Read More
                  </Link>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
        {totalPages > 1 && (
          <Pagination className="justify-content-center">
            <Pagination.First onClick={() => setCurrentPage(1)} />
            <Pagination.Prev
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
            />
            {[...Array(totalPages).keys()].map((number) => (
              <Pagination.Item
                key={number + 1}
                active={number + 1 === currentPage}
                onClick={() => setCurrentPage(number + 1)}
              >
                {number + 1}
              </Pagination.Item>
            ))}
            <Pagination.Next
              onClick={() =>
                setCurrentPage((prev) => Math.min(prev + 1, totalPages))
              }
            />
            <Pagination.Last onClick={() => setCurrentPage(totalPages)} />
          </Pagination>
        )}
      </Container>
    </div>
  );
};

export default Blog;
