import React from "react";
import { useRouteError } from "react-router-dom";
import { Link } from "react-router-dom";
import "./Error.scss";

const ErrorPage = () => {
  //      const error = useRouteError();
  //   console.error(error);
  return (
    <div className="error-page">
      <h1>404 - Page Not Found</h1>
      <p>Sorry, the page you are looking for does not exist.</p>
      <Link to="/" className="btn">
        Go Back Home
      </Link>

      {/* <p>
        <i>{error.statusText || error.message}</i>
      </p> */}
    </div>
  );
};

export default ErrorPage;
