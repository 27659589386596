import React, { useState } from "react";
import ProductBanner from "../../Assets/images/Banner/product-banner.jpg";
import AirCompressor from "../../Assets/images/Products/aircompressor-v1.jpg";
import Automation from "../../Assets/images/Products/Automation.jpg";
import Bearings from "../../Assets/images/Products/bearings-v1.jpg";
import Cables from "../../Assets/images/Products/cables-v1.jpg";
import DieselEngineAndGenerators from "../../Assets/images/Products/Diesel-engine-And-Generators-v1.jpg";
import ElectricEquipments from "../../Assets/images/Products/electric-equipments-v1.jpg";
import ElectricMotors from "../../Assets/images/Products/electric-motors-v1.jpg";
import Filters from "../../Assets/images/Products/filters-v2.jpg";
import GearMotors from "../../Assets/images/Products/gear-motors-v1.jpg";
import MarineAccesories from "../../Assets/images/Products/marine-Equipment-and-accesories-v1.jpg";
import PanelBoards from "../../Assets/images/Products/panel-boards-v3.jpg";
import { Tabs, Tab, Row, Col } from "react-bootstrap";
import "./Products.scss";
import { Link } from "react-router-dom";

const productImages = [
  { id: 1, title: "Air Compressor", src: AirCompressor },
  { id: 2, title: "Automation", src: Automation },
  { id: 3, title: "Bearings", src: Bearings },
  { id: 4, title: "Cables", src: Cables },
  {
    id: 5,
    title: "Diesel Engine And Generators",
    src: DieselEngineAndGenerators,
  },
  { id: 6, title: "Filters", src: Filters },
  { id: 7, title: "Electric Equipments", src: ElectricEquipments },
  { id: 8, title: "Electric Motors", src: ElectricMotors },
  { id: 9, title: "Gear Motors", src: GearMotors },
  {
    id: 10,
    title: "Marine Equipment and Accesories",
    src: MarineAccesories,
  },
  { id: 11, title: "Panel Boards", src: PanelBoards },
];

const Products = () => {
  const [key, setKey] = useState(productImages[0].id);
  return (
    <div className="products-container">
      <div className="banner">
        <img src={ProductBanner} alt="Banner" className="banner-image" />
        <h1 className="banner-title">Products</h1>
      </div>

      <div className="product-tabs-container">
        <Tabs
          id="product-tabs"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          className="mb-3 nav-btn"
          variant="pills"
          justify
        >
          {productImages.map((item) => (
            <Tab eventKey={item.id} title={item.title} key={item.id}>
              <div className="tab-content">
                <Row className="justify-content-center img-row">
                  <Col md="10" className="text-center">
                    <img
                      src={item.src}
                      alt={item.title}
                      className="tab-image"
                    />
                  </Col>
                </Row>
              </div>
            </Tab>
          ))}
        </Tabs>
      </div>

      <div
        className=""
        style={{ maxWidth: "165px", width: "100%", margin: "0 auto" }}
      >
        <Link to="/contact">
          <button className="cta">
            <span>Contact Us</span>
            <svg width="15px" height="10px" viewBox="0 0 13 10">
              <path d="M1,5 L11,5"></path>
              <polyline points="8 1 12 5 8 9"></polyline>
            </svg>
          </button>
        </Link>
      </div>
    </div>
  );
};

export default Products;
