import React, { useEffect, useState } from "react";
import { BiMenuAltRight } from "react-icons/bi";
import { AiOutlineClose } from "react-icons/ai";
import { NavLink, useNavigate } from "react-router-dom";
import Logo from "../../Assets/images/Banner/skyline-white.svg";
// import classes from "./Navbar.module.scss";
import "./Navbar.scss";

const Header = () => {
  //   const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const [size, setSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    const handleResize = () => {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (size.width > 870 && menuOpen) {
      setMenuOpen(false);
    }
  }, [size.width, menuOpen]);

  const menuToggleHandler = () => {
    setMenuOpen((p) => !p);
  };

  return (
    <header className="header">
      <div className="header__content">
        <NavLink to="/">
          <img className="header__content__logo" src={Logo} alt="logo" />
        </NavLink>
        <nav
          className={`header__content__nav ${
            menuOpen && size.width < 870 ? "isMenu" : ""
          }`}
        >
          <ul>
            <li>
              <NavLink
                to="/"
                className={({ isActive }) =>
                  isActive ? "link active" : "link"
                }
                onClick={menuToggleHandler}
              >
                Home
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/blogs"
                className={({ isActive }) =>
                  isActive || window.location.pathname.includes("/blog")
                    ? "link active"
                    : "link"
                }
                onClick={menuToggleHandler}
              >
                Blog
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/about"
                className={({ isActive }) =>
                  isActive ? "link active" : "link"
                }
                onClick={menuToggleHandler}
              >
                About
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/products"
                className={({ isActive }) =>
                  isActive ? "link active" : "link"
                }
                onClick={menuToggleHandler}
              >
                Products
              </NavLink>
            </li>
            <li className="contact-btn">
              <NavLink
                to="/contact"
                className={({ isActive }) =>
                  isActive ? "link active" : "link"
                }
                onClick={menuToggleHandler}
              >
                Contact Us
              </NavLink>
            </li>
          </ul>
        </nav>
        <div className="header__content__toggle">
          {!menuOpen ? (
            <BiMenuAltRight onClick={menuToggleHandler} />
          ) : (
            <AiOutlineClose onClick={menuToggleHandler} />
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
