import React from "react";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import contactBaner from "../../Assets/images/Banner/contact1.jpg";
import emailjs from "emailjs-com";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./ContactUs.scss";

const categories = [
  { value: "aircompressor", label: "Air Compressor" },
  { value: "automation", label: "Automation" },
  { value: "bearings", label: "Bearings" },
  { value: "cables", label: "Cables" },
  {
    value: "dieselengineandgenerators",
    label: "Diesel Engine And Generators",
  },
  { value: "filters", label: "Filters" },
  { value: "electricequipments", label: "Electric Equipments" },
  { value: "electricmotors", label: "Electric Motors" },
  { value: "gearmotors", label: "Gear Motors" },
  {
    value: "marineequipmentandaccesories",
    label: "Marine Equipment and Accesories",
  },
  { value: "panelboards", label: "Panel Boards" },
];

// Define the validation schema using Yup
const schema = Yup.object().shape({
  fullName: Yup.string().required("Full Name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  category: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .nullable()
    .required("Category is required"),
});

const ContactUs = () => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema), // Integrate Yup schema for validation
    defaultValues: {
      fullName: "",
      email: "",
      category: null,
      productName: "",
      message: "",
    },
  });

  const onSubmit = async (data) => {
    try {
      const templateParams = {
        fullName: data.fullName,
        email: data.email,
        category: data.category.label,
        productName: data.productName,
        message: data.message,
      };

      await emailjs.send(
        "service_24ygdee", // Replace with your EmailJS service ID
        "template_esrzgoc", // Replace with your EmailJS template ID
        templateParams,
        "PxNsoqj2l580rd7us" // Replace with your EmailJS user ID
      );

      reset(); // Reset the form fields to their default values
      toast.success("Email sent successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      toast.error("Error sending email. Please try again later.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  return (
    <div className="contactus-container">
      <div className="banner">
        <img src={contactBaner} alt="Banner" className="banner-image" />
        <h1 className="banner-title">Contact Us</h1>
      </div>

      <form onSubmit={handleSubmit(onSubmit)} className="contact-form">
        <div className="form-group">
          <label>Full Name</label>
          <input {...register("fullName")} className="form-control" />
          {errors.fullName && (
            <p className="error-message">{errors.fullName.message}</p>
          )}
        </div>
        <div className="form-group">
          <label>Email</label>
          <input {...register("email")} className="form-control" />
          {errors.email && (
            <p className="error-message">{errors.email.message}</p>
          )}
        </div>
        <div className="form-group">
          <label>Category</label>
          <Controller
            name="category"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                options={categories}
                placeholder="Select a category"
                isClearable
                className="react-select-container"
                classNamePrefix="react-select"
              />
            )}
          />
          {errors.category && (
            <p className="error-message">{errors.category.message}</p>
          )}
        </div>
        <div className="form-group">
          <label>Product Name</label>
          <input {...register("productName")} className="form-control" />
        </div>
        <div className="form-group">
          <label>Message</label>
          <textarea {...register("message")} className="form-control" />
        </div>

        <button className="submit-btn" type="submit">
          Submit
        </button>
      </form>
      <ToastContainer />
    </div>
  );
};

export default ContactUs;
