import React from "react";
import { useParams } from "react-router-dom";
import marineScrap from "../../../Assets/images/Banner/marine-scrap.jpg";
import leftoverMarine from "../../../Assets/images/Banner/leftover-marine.jpg";
import leftoverShip from "../../../Assets/images/Banner/leftovership.jpg";
import marine from "../../../Assets/images/Banner/marine.jpg";
import generator from "../../../Assets/images/Banner/generator.jpg";
import ship2 from "../../../Assets/images/Banner/ship2.jpg";
import { Row, Col } from "react-bootstrap";
import "./Blog.scss";

const recentBlogs = [
  {
    id: 1,
    image: marine,
    title: "Marine Automation in 2024: Trends, Challenges, and Opportunities",
    content: [
      "<h2>Introduction</h2>",
      "<p>Marine automation has been steadily advancing over the past few years, and 2024 is set to be a landmark year for the industry. As technological innovations continue to evolve, the maritime sector is embracing automation to enhance efficiency, safety, and sustainability. In this blog post, we will explore the key trends, challenges, and opportunities in marine automation in 2024.</p>",

      "<h2>Key Trends in Marine Automation</h2>",
      "<p>The maritime industry is witnessing several trends that are driving the adoption of automation technologies:</p>",

      `<img src=${ship2} alt="ship2"/>`,
      "<h3>1. Autonomous Vessels</h3>",
      "<p>Autonomous vessels are no longer a distant dream. In 2024, we are seeing increased deployment of autonomous ships equipped with advanced navigation systems and AI-powered decision-making capabilities. These vessels can operate with minimal human intervention, reducing operational costs and enhancing safety.</p>",
      "<h3>2. Advanced Sensor Technologies</h3>",
      "<p>Sensor technologies have become more sophisticated, enabling real-time monitoring and data collection. These sensors provide critical information about vessel performance, environmental conditions, and cargo status, allowing for more informed decision-making and proactive maintenance.</p>",
      "<h3>3. Integration of IoT and Big Data</h3>",
      "<p>The Internet of Things (IoT) and Big Data analytics are transforming marine automation. Connected devices onboard ships generate vast amounts of data, which can be analyzed to optimize routes, predict maintenance needs, and improve overall operational efficiency.</p>",
      "<h3>4. Enhanced Cybersecurity Measures</h3>",
      "<p>With the increased reliance on digital technologies, cybersecurity has become a top priority in marine automation. In 2024, there is a heightened focus on implementing robust cybersecurity measures to protect vessels from cyber threats and ensure the integrity of critical systems.</p>",

      "<h2>Challenges in Marine Automation</h2>",
      "<p>Despite the numerous benefits, the adoption of marine automation is not without its challenges:</p>",
      "<h3>1. Regulatory and Compliance Issues</h3>",
      "<p>The maritime industry operates under stringent regulations, and the integration of automation technologies must comply with these standards. Navigating the complex regulatory landscape can be a significant challenge for stakeholders.</p>",
      "<h3>2. High Initial Investment</h3>",
      "<p>Implementing automation technologies requires substantial upfront investment. The cost of acquiring and installing advanced systems can be prohibitive for smaller operators, limiting widespread adoption.</p>",
      "<h3>3. Skill Gap</h3>",
      "<p>The transition to automated systems necessitates a workforce with specialized skills in handling and maintaining these technologies. Bridging the skill gap and providing adequate training to maritime personnel is crucial for successful implementation.</p>",

      "<h2>Opportunities in Marine Automation</h2>",
      "<p>Marine automation presents several opportunities for the maritime industry:</p>",
      "<h3>1. Increased Operational Efficiency</h3>",
      "<p>Automation can significantly enhance operational efficiency by optimizing vessel performance, reducing fuel consumption, and minimizing downtime. This leads to cost savings and improved profitability for operators.</p>",
      "<h3>2. Enhanced Safety and Reliability</h3>",
      "<p>Automated systems can improve safety by reducing human error and enabling real-time monitoring of critical parameters. This enhances the reliability of operations and minimizes the risk of accidents.</p>",
      "<h3>3. Environmental Sustainability</h3>",
      "<p>Automation technologies can contribute to environmental sustainability by optimizing fuel usage, reducing emissions, and promoting eco-friendly practices. This aligns with the industry's commitment to reducing its carbon footprint.</p>",

      "<h2>Conclusion</h2>",
      "<p>Marine automation in 2024 is poised to revolutionize the maritime industry. With advancements in autonomous vessels, sensor technologies, IoT integration, and cybersecurity, the future looks promising. However, addressing regulatory challenges, high initial investments, and skill gaps is essential for successful implementation. As the industry continues to evolve, embracing automation will be key to achieving greater efficiency, safety, and sustainability in maritime operations.</p>",
    ].join(""),
  },

  {
    id: 2,
    image: marineScrap,
    title: "Effect of Marine Scrap on the Modern World",
    content: [
      "<h2>Introduction</h2>",
      "<p>Marine scrap, the discarded materials from ships and marine equipment, has a significant impact on the modern world. As the maritime industry continues to grow, the management of marine scrap becomes increasingly important. This blog post explores the effects of marine scrap on the environment, economy, and society in 2024.</p>",

      "<h2>Environmental Impact of Marine Scrap</h2>",
      "<p>The environmental impact of marine scrap is a major concern. When not properly managed, marine scrap can lead to pollution and habitat destruction:</p>",
      "<h3>1. Ocean Pollution</h3>",
      "<p>Discarded materials from ships, such as metals, plastics, and hazardous substances, can end up in the ocean, causing pollution. This pollution can harm marine life, disrupt ecosystems, and contaminate the food chain.</p>",
      "<h3>2. Habitat Destruction</h3>",
      "<p>Improper disposal of large marine structures can damage coral reefs, seabeds, and other critical habitats. This destruction can have long-term consequences for biodiversity and the health of marine ecosystems.</p>",

      "<h2>Economic Impact of Marine Scrap</h2>",
      "<p>While marine scrap poses environmental challenges, it also presents economic opportunities:</p>",
      "<h3>1. Recycling and Resource Recovery</h3>",
      "<p>Marine scrap can be recycled and repurposed, providing valuable materials for various industries. Recycling marine scrap reduces the need for new raw materials, conserving natural resources and reducing energy consumption.</p>",
      "<h3>2. Job Creation</h3>",
      "<p>The recycling and management of marine scrap create job opportunities in sectors such as waste management, recycling, and environmental remediation. These jobs contribute to local economies and support sustainable development.</p>",

      "<h2>Social Impact of Marine Scrap</h2>",
      "<p>The management of marine scrap also has social implications:</p>",
      "<h3>1. Health and Safety</h3>",
      "<p>Improperly managed marine scrap can pose health and safety risks to communities. Exposure to hazardous materials and pollutants can lead to respiratory issues, skin conditions, and other health problems.</p>",
      "<h3>2. Community Engagement</h3>",
      "<p>Effective management of marine scrap involves community engagement and education. Raising awareness about the importance of proper disposal and recycling can lead to more responsible behavior and stronger environmental stewardship.</p>",

      "<h2>Conclusion</h2>",
      "<p>The effect of marine scrap on the modern world is multifaceted, encompassing environmental, economic, and social dimensions. In 2024, addressing the challenges and harnessing the opportunities associated with marine scrap is crucial for sustainable development. By implementing effective management practices, promoting recycling, and engaging communities, we can mitigate the negative impacts of marine scrap and contribute to a healthier, more sustainable world.</p>",
    ].join(""),
  },
  {
    id: 3,
    image: leftoverShip,
    title:
      "What We Can Do to Avoid Leftover Marine Products Like Panels, Automation Products, etc.",
    content: [
      "<h2>Introduction</h2>",
      "<p>The maritime industry generates a significant amount of leftover products, such as panels and automation equipment, at the end of their lifecycle. Properly managing these leftover products is essential to minimize waste and environmental impact. This blog post discusses strategies to avoid leftover marine products and promote sustainability in the maritime sector.</p>",

      "<h2>Strategies to Avoid Leftover Marine Products</h2>",
      "<p>There are several strategies that can be implemented to reduce and manage leftover marine products:</p>",
      "<h3>1. Recycling and Reuse</h3>",
      "<p>Recycling and reusing marine products is one of the most effective ways to manage leftovers. By recycling materials and components, we can reduce the demand for new resources and minimize waste. Reusing equipment in other applications can extend its lifecycle and reduce the need for new products.</p>",
      "<h3>2. Upgrading and Retrofitting</h3>",
      "<p>Upgrading and retrofitting existing marine equipment can prolong its usable life and improve performance. Instead of discarding old panels and automation products, consider upgrading them with new technology or retrofitting them for other uses. This approach not only reduces waste but also enhances operational efficiency.</p>",
      "<h3>3. Proper Maintenance</h3>",
      "<p>Regular maintenance of marine equipment can prevent premature failure and extend its lifespan. Implementing a proactive maintenance schedule ensures that panels, automation products, and other equipment remain in good working condition, reducing the likelihood of generating leftovers.</p>",

      "<h2>Promoting a Circular Economy</h2>",
      "<p>Adopting a circular economy model in the maritime industry can significantly reduce leftover marine products. This model focuses on keeping products and materials in use for as long as possible through recycling, reuse, and refurbishment:</p>",
      "<h3>1. Design for Longevity</h3>",
      "<p>Designing marine products with longevity in mind can reduce waste. By creating durable and adaptable equipment, manufacturers can ensure that products have a longer lifecycle and are easier to upgrade or repurpose.</p>",
      "<h3>2. Collaboration and Partnerships</h3>",
      "<p>Collaboration between manufacturers, shipowners, and recycling companies is essential to promote a circular economy. Partnerships can facilitate the collection, recycling, and repurposing of leftover marine products, ensuring that they do not end up as waste.</p>",

      "<h2>Conclusion</h2>",
      "<p>Avoiding leftover marine products requires a comprehensive approach that includes recycling, reuse, upgrading, maintenance, and adopting a circular economy model. By implementing these strategies, the maritime industry can minimize waste, reduce environmental impact, and promote sustainability. In 2024 and beyond, it is crucial for stakeholders to work together to achieve these goals and create a more sustainable future for the maritime sector.</p>",
    ].join(""),
  },
];

const Blog = () => {
  const { id } = useParams();
  const blog = recentBlogs?.find((blog) => blog.id === parseInt(id));

  if (!blog) {
    return (
      <div
        style={{
          padding: "2rem",
          textAlign: "center",
          fontSize: "1.5rem",
          color: "#191919",
        }}
      >
        Blog not found
      </div>
    );
  }

  return (
    <div className="blog-post-container">
      <div className="banner">
        <img src={blog.image} alt={blog.title} className="banner-image" />
      </div>
      <div className="blog-detail">
        <Row>
          <Col>
            <h1 className="title">{blog.title}</h1>
            <div dangerouslySetInnerHTML={{ __html: blog.content }} />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Blog;
