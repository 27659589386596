import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BannerImage from "../../Assets/images/Banner/about.jpg";
import Who from "../../Assets/images/Banner/who.jpg";
import We from "../../Assets/images/Banner/we.jpg";
import Are from "../../Assets/images/Banner/are.jpg";
import "./About.scss";

const aboutContent = [
  {
    id: 1,
    Title: "Who",
    desc: "Leading supplier of marine and industrial equipment since 2010.",
    img: Who,
  },
  {
    id: 2,
    Title: "We",
    desc: "We are Skyline Marine, a dedicated team of professionals with a passion for providing top-notch marine and industrial equipment to our clients worldwide. Our experience and expertise make us a trusted name in the industry.",
    img: We,
  },
  {
    id: 3,
    Title: "Are",
    desc: "Our mission is to be the global leader in marine and industrial equipment supply. We prioritize integrity, quality, safety, and customer satisfaction in everything we do, striving to exceed expectations at every turn.",
    img: Are,
  },
];

const About = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", function () {
      setScreenWidth(window.innerWidth);
    });
  }, []);

  return (
    <div className="about-container">
      <div className="banner">
        <img src={BannerImage} alt="Banner" className="banner-image" />
        <h1 className="banner-title">About Us</h1>
      </div>

      {screenWidth > 1240 ? (
        <div className="about-card">
          {aboutContent?.map((item, index) => {
            return (
              <div className="card" key={index}>
                <strong>{item?.Title}</strong>
                <span>{item?.desc}</span>
              </div>
            );
          })}
        </div>
      ) : (
        <>
          {aboutContent?.map((item, index) => {
            return (
              <div
                className={`responsive-about-sec ${
                  index % 2 === 1 ? "row-reverse" : ""
                }`}
                key={index}
              >
                <div className="img-div">
                  <img
                    src={item?.img}
                    alt={item?.Title}
                    className="about-img"
                  />
                </div>
                <div className="txt-div">
                  <h3>{item?.Title}</h3>
                  <p>{item?.desc}</p>
                </div>
              </div>
            );
          })}
        </>
      )}

      <div
        className=""
        style={{ maxWidth: "165px", width: "100%", margin: "0 auto" }}
      >
        <Link to="/contact">
          <button className="cta">
            <span>Contact Us</span>
            <svg width="15px" height="10px" viewBox="0 0 13 10">
              <path d="M1,5 L11,5"></path>
              <polyline points="8 1 12 5 8 9"></polyline>
            </svg>
          </button>
        </Link>
      </div>
    </div>
  );
};

export default About;
