import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import Home from "./Pages/Home/index";
import Blog from "./Pages/Blogs";
import IndividualBlog from "./Pages/Blogs/Blog";
import About from "./Pages/About";
import ContactUs from "./Pages/ContactUs";
import Products from "./Pages/Products";
import { FaWhatsapp } from "react-icons/fa";
import "./App.scss";
import ScrollToTop from "./Components/ScrollToTop";
import ErrorPage from "./Components/Error";

const App = () => {
  const location = useLocation();
  return (
    <div className="app-container">
      <ScrollToTop />
      <Navbar />
      <div
        className={
          location.pathname === "/contact" ? "contact-bg content" : "content"
        }
      >
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/blogs" element={<Blog />} />
          <Route path="/blog/:id" element={<IndividualBlog />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/products" element={<Products />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </div>
      <Footer />
      <a
        href="https://wa.me/+919558383856"
        className="whatsapp-button"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaWhatsapp size={30} />
      </a>
    </div>
  );
};

export default App;
